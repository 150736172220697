import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import { Link, TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContractPaymentTermsType,
  getDefaultInvoiceCode,
  getDefaultInvoiceDates,
  integrationTypes,
  MAX_CMIC_INVOICE_NUMBER_LENGTH,
} from 'siteline-common-all'
import { colors, makeStylesFast, SitelineText } from 'siteline-common-web'
import { Column } from '../../../common/components/Column'
import {
  DatePickerInput,
  DatePickerValue,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { DollarNumberFormat } from '../../../common/components/NumberFormat'
import { Row } from '../../../common/components/Row'
import {
  SitelineDialog,
  SitelineDialogProps,
  STANDARD_FIXED_DIALOG_TOP,
} from '../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  MinimalIntegrationProperties,
  useGetPayAppQuery,
} from '../../../common/graphql/apollo-operations'
import { isWriteSyncInProgress, useWriteSync } from '../../../common/util/Integration'
import { trackIntegrationSyncDialogReset } from '../../../common/util/MetricsTracking'
import { payAppHasAnyProgressBilling } from '../../../common/util/PayApp'
import { WriteSyncDialogContent } from './WriteSyncDialogContent'

const DROPDOWN_WIDTH = 200
const SECTION_WIDTH = 100
const HELP_CENTER_URL =
  'https://support.siteline.com/hc/en-us/articles/34495901274388/live_preview/01JK6KKP3HGPDK3H0Z8XE81RRQ'
const i18nBase = 'integrations.cmic_sync_dialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  help: {
    marginTop: theme.spacing(1),
  },
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 420,
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      '& .row': {
        marginTop: theme.spacing(1),
        gap: theme.spacing(5),
        '& .leftSection': {
          width: SECTION_WIDTH,
          display: 'flex',
          justifyContent: 'space-between',
          gap: theme.spacing(8),
        },
        '& .tooltip': {
          width: 16,
          height: 16,
          color: colors.grey50,
        },
      },
      '& .noDefaultCode': {
        marginTop: theme.spacing(-1),
      },
      '& .setting': {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        margin: theme.spacing(1.5, 0),
        whiteSpace: 'nowrap',
        '&.doubleGap': {
          gap: theme.spacing(5),
        },
        '& .dateInput': {
          whiteSpace: 'nowrap',
        },
      },
    },
    '& .invoiceNumberText': {
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    '& .dateText': {
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(5),
    },
    '& .dateInput': {
      width: DROPDOWN_WIDTH,
      justifyContent: 'space-between',
    },
    '& .invoiceCodeInput': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: DROPDOWN_WIDTH,
      '& .generateCode': {
        marginLeft: theme.spacing(1),
      },
    },
    '& .loadingAccounts': {
      height: 40,
      display: 'flex',
      alignItems: 'center',
    },
    '& .numberInput': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      ...theme.typography.body1,
      '&:hover': {
        borderColor: colors.grey90,
      },
    },
  },
  syncSuccess: {
    '& .MuiDialog-paper': {
      maxWidth: 720,
    },
    '& .MuiDialogContent-root': {
      margin: theme.spacing(-2.5),
    },
  },
  autocomplete: {
    backgroundColor: colors.white,
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      maxWidth: 'unset !important',
    },
  },
  readOnlyAccount: {
    marginTop: theme.spacing(1),
  },
}))

interface SyncCmicDialogProps {
  open: boolean
  onClose: () => void
  integration: MinimalIntegrationProperties
  payAppId: string
}
/**
 * Dialog that gives options to select from when syncing an invoice to CMiC.
 */
export function SyncCmicDialog({ open, onClose, integration, payAppId }: SyncCmicDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id: projectId, contract, timeZone, projectNumber } = useProjectContext()
  const { companyAgingIntervalType } = useCompanyContext()
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [invoiceDate, setInvoiceDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const [dueDate, setDueDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const integrationLongName = integration.longName

  const {
    data,
    loading: loadingPayApp,
    error,
  } = useGetPayAppQuery({ variables: { payAppId }, skip: !open })

  const payAppTotal = useMemo(
    // NOTE this is pending
    // better tax handling in https://zube.io/siteline/siteline/c/19738
    () => data?.payApp.amountDuePreTax ?? 0,
    [data?.payApp.amountDuePreTax]
  )

  const retentionAmount = useMemo(
    () => data?.payApp.currentRetention ?? 0,
    [data?.payApp.currentRetention]
  )

  const hasAnyBilling = useMemo(() => {
    const payApp = data?.payApp
    if (!payApp) {
      return false
    }
    return payAppHasAnyProgressBilling(payApp)
  }, [data])

  const { sync, status, reset: resetSync } = useWriteSync({ integration })

  const payload = useMemo((): integrationTypes.WriteSyncPayloadPayAppCmic | null => {
    if (!invoiceDate.date || !dueDate.date || !invoiceNumber || error) {
      return null
    }

    return {
      type: 'payAppCmic',
      payAppId,
      invoiceDate: invoiceDate.date.format('YYYY-MM-DD'),
      dueDate: dueDate.date.format('YYYY-MM-DD'),
      invoiceCode: invoiceNumber,
    }
  }, [invoiceDate.date, dueDate.date, invoiceNumber, error, payAppId])

  let dialogTitle = t(`${i18nBase}.header`)
  let cancelLabel: string | undefined
  let handleClose: (() => void) | undefined
  let handleSubmit: (() => void) | undefined
  let className: string | undefined
  let actionsLayout: SitelineDialogProps['actionsLayout'] = 'actionsRow'
  let simpleErrorMessage: string | undefined

  if (status.type !== 'notCreated') {
    dialogTitle = ''
    actionsLayout = 'closeIcon'
    className = isWriteSyncInProgress(status) ? undefined : classes.syncSuccess
  } else if (data?.payApp.previousRetentionBilled !== 0) {
    // We don't support this right now
    handleClose = onClose
    dialogTitle = t(`${i18nBase}.create_in_cmic`)
    simpleErrorMessage = t(`${i18nBase}.errors.billing_retention`)
  } else if (!hasAnyBilling) {
    handleClose = onClose
    simpleErrorMessage = t(`${i18nBase}.errors.no_billing`)
  } else {
    className = clsx(classes.root, {
      wideSovView: !loadingPayApp,
    })
    handleClose = onClose
    handleSubmit = () => {
      if (!payload) {
        return
      }
      sync(payload)
    }
  }

  const invoiceNumberTooLong = invoiceNumber.length > MAX_CMIC_INVOICE_NUMBER_LENGTH
  const disableSubmit = !payload || invoiceNumberTooLong

  const showContent = !error && status.type === 'notCreated' && !simpleErrorMessage
  const showMainContent = !loadingPayApp && showContent

  let invoiceNumberHelperText: string | undefined
  if (invoiceNumberTooLong) {
    invoiceNumberHelperText = t(
      `${i18nBase}.errors.invoice_number_length_long`,
      String(MAX_CMIC_INVOICE_NUMBER_LENGTH),
      { maxChars: MAX_CMIC_INVOICE_NUMBER_LENGTH }
    )
  }

  const resetDates = useCallback(() => {
    if (!data) {
      return
    }
    const paymentTerms = _.isNumber(contract?.paymentTerms) ? contract.paymentTerms : null
    const { invoiceDate, dueDate } = getDefaultInvoiceDates({
      agingIntervalType: companyAgingIntervalType,
      timeZone,
      paymentTerms,
      billingEnd: moment.tz(data.payApp.billingEnd, timeZone),
      submittedAt: data.payApp.lastSubmitted
        ? moment.tz(data.payApp.lastSubmitted.statusUpdatedAt, timeZone)
        : null,
    })
    setInvoiceDate(makeDatePickerValue(invoiceDate))
    setDueDate(makeDatePickerValue(dueDate))
  }, [companyAgingIntervalType, contract?.paymentTerms, data, timeZone])

  const resetDialog = useCallback(
    (trackClick: boolean) => {
      if (!data) {
        return
      }

      // Reset all dates
      resetDates()

      if (trackClick) {
        trackIntegrationSyncDialogReset({ projectId, payAppId, integrationLongName })
      }
    },
    [data, resetDates, projectId, payAppId, integrationLongName]
  )

  // When we have all the data, try to auto-populate as best as we can. Always reset the dialog
  // when you change between pay apps.
  useEffect(() => {
    resetDialog(false)
  }, [resetDialog, payAppId])

  const defaultInvoiceNumber = useMemo(
    () =>
      getDefaultInvoiceCode({
        internalProjectNumber: contract?.internalProjectNumber,
        projectNumber,
        payAppNumber: data?.payApp.payAppNumber,
        maxInvoiceCodeLength: MAX_CMIC_INVOICE_NUMBER_LENGTH,
        billingType: data?.payApp.billingType,
      }),
    [contract?.internalProjectNumber, data, projectNumber]
  )

  // Assign the invoice number based on project number and pay app number. This essentially only gets
  // called once when the dialog is first rendered.
  useEffect(() => {
    setInvoiceNumber(defaultInvoiceNumber)
  }, [defaultInvoiceNumber])

  return (
    <SitelineDialog
      title={dialogTitle}
      open={open}
      onClose={handleClose}
      cancelLabel={cancelLabel}
      onSubmit={handleSubmit}
      submitLabel={t('integrations.button.sync')}
      disableSubmit={disableSubmit}
      className={className}
      maxWidth={isWriteSyncInProgress(status) ? 'sm' : 'md'}
      actionsLayout={actionsLayout}
      disableEscapeKeyDown
      fixedTopPosition={STANDARD_FIXED_DIALOG_TOP}
      subtitle={
        showContent && (
          <Link target="_blank" href={HELP_CENTER_URL} underline="none">
            <SitelineText
              variant="h4"
              color="blue50"
              endIcon={<LaunchIcon fontSize="small" />}
              className={classes.help}
            >
              {t(`${i18nBase}.help_center`)}
            </SitelineText>
          </Link>
        )
      }
    >
      {payload && (
        <WriteSyncDialogContent
          integration={integration}
          projectId={projectId}
          onClose={handleClose ?? onClose}
          onBack={resetSync}
          onSyncAgain={() => sync(payload)}
          payload={payload}
          status={status}
        />
      )}
      {simpleErrorMessage && <SitelineText variant="secondary">{simpleErrorMessage}</SitelineText>}
      {showMainContent && (
        <div className="container">
          <div className="row">
            <div className="leftSection">
              <Column justifyContent="flex-start" alignItems="flex-start" className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount`)}
                </SitelineText>
                <SitelineText variant="h3" bold>
                  <DollarNumberFormat value={payAppTotal} />
                </SitelineText>
              </Column>
              <Column className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.retention_amount`)}
                </SitelineText>
                <SitelineText variant="h3" bold>
                  <DollarNumberFormat value={retentionAmount} />
                </SitelineText>
              </Column>
            </div>
          </div>
          <div className="row">
            {invoiceNumberHelperText && (
              <Row justifyContent="flex-end" alignItems="flex-end">
                <div className="noDefaultNumber">
                  <SitelineText
                    variant="smallText"
                    color={invoiceNumberTooLong ? 'red50' : 'grey50'}
                  >
                    {invoiceNumberHelperText}
                  </SitelineText>
                </div>
              </Row>
            )}
          </div>
          <div className="row">
            <Row justifyContent="space-between" alignItems="flex-start">
              <SitelineText variant="secondary" bold color="grey70" className="invoiceNumberText">
                {t(`${i18nBase}.invoice_number`)}
              </SitelineText>
              <TextField
                variant="outlined"
                error={invoiceNumberTooLong}
                value={invoiceNumber}
                onChange={(ev) => setInvoiceNumber(ev.target.value)}
                className="invoiceCodeInput"
                autoFocus={true}
              />
            </Row>
          </div>
          <div className="row">
            <Row justifyContent="space-between" alignItems="flex-start">
              <SitelineText variant="secondary" bold color="grey70" className="dateText">
                {t(`${i18nBase}.invoice_date`)}
              </SitelineText>
              <DatePickerInput
                value={invoiceDate}
                onChange={(value) =>
                  setInvoiceDate({
                    ...value,
                    date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                  })
                }
                timeZone={timeZone}
                className="dateInput"
              />
            </Row>
          </div>

          <div className="row">
            <Row justifyContent="space-between" alignItems="flex-start">
              <SitelineText
                variant="secondary"
                bold
                color="grey70"
                className="dateText"
                endIcon={
                  <Tooltip
                    title={
                      contract?.paymentTermsType === ContractPaymentTermsType.NET_PAYMENT &&
                      _.isNumber(contract.paymentTerms)
                        ? t(`${i18nBase}.due_date_tooltip_terms`)
                        : t(`${i18nBase}.due_date_tooltip`)
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon className="tooltip" />
                  </Tooltip>
                }
              >
                {t(`${i18nBase}.due_date`)}
              </SitelineText>
              <DatePickerInput
                value={dueDate}
                onChange={(value) =>
                  setDueDate({
                    ...value,
                    date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                  })
                }
                timeZone={timeZone}
                className="dateInput"
              />
            </Row>
          </div>
        </div>
      )}
    </SitelineDialog>
  )
}
