import { NavigateOptions } from '@tanstack/react-router'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useMemo } from 'react'
import {
  CompanyUserRole,
  PayAppEventType,
  PayAppForSorting,
  PhysicalSignatureRequired,
  PreSitelinePayAppStatus,
  StoredMaterialsCarryoverType,
  TypedPayAppEvent,
  centsToDollars,
  decimalToPercent,
  safeDivide,
  sortPayAppsByBillingEnd,
} from 'siteline-common-all'
import { PreSitelinePayApp, colors } from 'siteline-common-web'
import {
  BillingType,
  ContractStatus,
  MinimalContractProperties,
  MinimalPayAppProperties,
  MinimalPreSitelinePayAppProperties,
  NewPayAppCommentDocument,
  NewPayAppCommentSubscription,
  NewPayAppEventDocument,
  PayAppEventProperties,
  PayAppProperties,
  PayAppStatus,
  PayAppStatus as PayAppStatusEnum,
  Permission,
  SovLineItemInput,
  SovLineItemProgressProperties,
  SovProperties,
  WorksheetLineItemProgressProperties,
  usePayAppActivityQuery,
} from '../../common/graphql/apollo-operations'
import { BillingPathType, PayAppTab, getBillingPath } from '../../components/billing/Billing.lib'
import { ContractForPayApps } from '../../components/billing/PayAppDetails'
import {
  ContractForProjectHome,
  PayAppForProjectHome,
  PreSitelinePayAppForProjectHome,
} from '../../components/billing/home/ProjectHome'
import { PayAppForProgress } from '../../components/billing/invoice/LumpSumPayAppInvoice'
import { PayApp } from '../graphql/Fragments'
import { adjustDateByMonths, matchEndOfMonth } from './Dates'
import { MetricsForPayApp, MetricsForPreSitelinePayApp } from './MetricsTracking'
import { getProjectFromCache } from './Project'

type BaseSortProgressFields = {
  sovLineItem: {
    sortOrder: number
    code: string
  }
}

/** Generates the path to a pay app, optionally with the specific tab to open */
export function payAppPath({
  projectId,
  payAppId,
  tab,
}: {
  projectId: string
  payAppId: string
  tab?: PayAppTab | 'unconditional'
}): NavigateOptions {
  const sharedParams = { projectId, payAppId }

  if (tab === 'unconditional') {
    return getBillingPath({
      pathType: BillingPathType.PayAppUnconditional,
      payAppTab: 'unconditional',
      ...sharedParams,
    })
  }

  return getBillingPath({
    pathType: BillingPathType.PayApp,
    payAppTab: tab,
    ...sharedParams,
  })
}

export function sortSovLineItemProgress(a: BaseSortProgressFields, b: BaseSortProgressFields) {
  return a.sovLineItem.sortOrder > b.sovLineItem.sortOrder ? 1 : -1
}
export function sortSovLineItemInputs(a: SovLineItemInput, b: SovLineItemInput) {
  return a.sortOrder > b.sortOrder ? 1 : -1
}

export const totalPayAppBilled = (
  progress: Pick<SovLineItemProgressProperties, 'previousBilled' | 'currentBilled'>[]
) => {
  return progress.reduce((a, b) => a + b.previousBilled + b.currentBilled, 0)
}

export const previousPayAppBilled = (
  progress: Pick<SovLineItemProgressProperties, 'previousBilled'>[]
) => {
  return progress.reduce((a, b) => a + b.previousBilled, 0)
}

export const previousPayAppWorkCompleted = (
  progress: Pick<SovLineItemProgressProperties, 'previousWorkCompleted'>[]
) => {
  return progress.reduce((a, b) => a + b.previousWorkCompleted, 0)
}

export const currentPayAppBilled = (
  progress: Pick<SovLineItemProgressProperties, 'currentBilled'>[]
) => {
  return progress.reduce((a, b) => a + b.currentBilled, 0)
}

export const currentPayAppProgress = (
  progress: Pick<SovLineItemProgressProperties, 'progressBilled'>[]
) => {
  return progress.reduce((a, b) => a + b.progressBilled, 0)
}

export const currentPayAppMaterialsStored = (
  progress: Pick<
    SovLineItemProgressProperties,
    'storedMaterialBilled' | 'materialsInStorageThroughCurrentPayApp'
  >[],
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
) => {
  return progress.reduce((a, b) => {
    if (storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL) {
      return a + b.materialsInStorageThroughCurrentPayApp
    }
    return a + b.storedMaterialBilled
  }, 0)
}

/** Returns a number from 0 to 1 for how complete the pay app is to date. */
type PayAppForPercentComplete = Pick<
  PayAppForProjectHome,
  'currentBilled' | 'payAppNumber' | 'billingEnd' | 'totalValue'
>
export function payAppPercentComplete(
  payApp: PayAppForPercentComplete,
  allPayApps: PayAppForPercentComplete[],
  sov: Pick<SovProperties, 'lineItems'>,
  timeZone: string
): number {
  const lineItems = sov.lineItems.filter((lineItem) => {
    // If a part of the original SOV, include it
    if (!lineItem.isChangeOrder || !lineItem.changeOrderApprovedDate) {
      return true
    }
    // If change order, only include it if effective date is before pay app billingEnd date
    const date = moment.tz(
      lineItem.changeOrderEffectiveDate ?? lineItem.changeOrderApprovedDate,
      timeZone
    )
    const payAppEnd = moment.tz(payApp.billingEnd, timeZone)
    return date.isSameOrBefore(payAppEnd)
  })

  const totalBilledToPayApp = _.chain(allPayApps)
    .filter((projectPayApp) => projectPayApp.payAppNumber <= payApp.payAppNumber)
    .map((projectPayApp) => projectPayApp.currentBilled)
    .sum()
    .value()
  const previouslyBilled = _.sumBy(lineItems, (lineItem) => lineItem.previousBilled)
  const totalBilled = totalBilledToPayApp + previouslyBilled

  return safeDivide(totalBilled, payApp.totalValue, 0)
}

/**
 * This calculates the total amount billed on a progress line item to date
 */
export function totalBilledOnProgress(progress: SovLineItemProgressProperties) {
  return (
    progress.previousBilled + // Total to date (not including current month)
    progress.progressBilled + // Current month: progress
    progress.storedMaterialBilled // Current month: stored materials
  )
}

/**
 * This calculates the total amount billed on a line item all time
 */
export function totalBilledOnLineItem(progress: SovLineItemProgressProperties) {
  return (
    progress.previousBilled + // Total to date (not including current month)
    progress.progressBilled + // Current month: progress
    progress.storedMaterialBilled + // Current month: stored materials
    progress.futureBilled // Total in future months
  )
}

/**
 * This calculates the % complete for a sov line item progress. A total value can be provided to
 * be used instead of the default value - specifically in the case where the user is editing the
 * SOV.
 *
 * @param progress Progress to check % complete
 * @param totalValue Total value to use to compute % complete
 */
export function currentPercentComplete(progress: SovLineItemProgressProperties) {
  const currentTotalValue = progress.totalValue
  if (currentTotalValue === 0) {
    return 0
  }

  const totalBilled = totalBilledOnProgress(progress)
  return decimalToPercent(safeDivide(totalBilled, currentTotalValue, 1), 2)
}

/** Equivalent function to `currentPercentComplete`, but for worksheet line item progress */
export function currentWorksheetProgressPercentComplete(
  progress: WorksheetLineItemProgressProperties
) {
  const currentTotalValue = progress.totalValue
  if (currentTotalValue === 0) {
    return 0
  }

  const totalBilled = progress.previousBilled + progress.progressBilled
  return decimalToPercent(safeDivide(totalBilled, currentTotalValue, 1), 2)
}

/**
 * Rounds the % complete for a line item to the nearest whole number. If the number is above 99% but
 * not 100% complete, then use 99% to indicate its not done. If the number is above 0% but below
 * 1%, then use 1% to indicate its been started
 */
export function roundPercentComplete(percent: number): number {
  if (percent > 99 && percent < 100) {
    return _.floor(percent)
  }
  if (percent > 0 && percent < 1) {
    return _.ceil(percent)
  }
  return _.round(percent)
}

export type LienWaiverTypeForI18n = 'final' | 'progress'

/**
 * This calculates the balance to finish for a sov line item progress. A total value can be provided
 * to be used instead of the default value - specifically in the case where the user is editing the
 * SOV.
 *
 * @param progress Progress to check balance to finish for
 * @param totalValue Total value to use to compute balance to finish
 */
export function balanceToFinish(progress: SovLineItemProgressProperties) {
  const currentTotalValue = progress.totalValue
  const totalBilled = totalBilledOnProgress(progress)
  return currentTotalValue - totalBilled
}

/** Equivalent of `balanceToFinish` for worksheet line item progress */
export function balanceToFinishForWorksheet(progress: WorksheetLineItemProgressProperties) {
  const currentTotalValue = progress.totalValue
  const totalBilled = progress.previousBilled + progress.progressBilled
  return currentTotalValue - totalBilled
}

export const balanceToFinishForFullPayApp = (progress: SovLineItemProgressProperties[]) => {
  return progress.reduce((a, b) => a + balanceToFinish(b), 0)
}

export const getCentString = (cents: number) => {
  let centsStr = centsToDollars(cents).toString().split('.')[1]
  if (!centsStr || centsStr.length === 0) {
    centsStr = '00'
  } else if (centsStr.length === 1) {
    centsStr = `${centsStr}0`
  }
  return centsStr
}

/**
 * Returns true if a pay app is not in draft or paid and can be reset to draft. We allow reverting
 * paid pay apps to paid in certain cases but not in as many workflows as we support reverting
 * signed and submitted pay apps.
 */
export const isUnpaidPayAppResettable = (status: PayAppStatus): boolean => {
  return (
    !isPayAppDraftOrSyncFailed(status) &&
    status !== PayAppStatus.PAID &&
    status !== PayAppStatus.NOTARIZING_UNCONDITIONAL
  )
}

/** We support reverting all pay apps to draft after they've been signed */
export const isPayAppResettable = (status: PayAppStatus): boolean => {
  return !isPayAppDraftOrSyncFailed(status)
}

export const isPayAppSubmittedOrSynced = (status: PayAppStatus): boolean => {
  return status === PayAppStatus.SYNCED || status === PayAppStatus.PROPOSED
}

export const isPayAppCompleted = (status: PayAppStatus): boolean => {
  return status === PayAppStatus.PAID || status === PayAppStatus.NOTARIZING_UNCONDITIONAL
}

const isPreSitelinePayAppSubmitted = ({
  status,
  currentBilled,
  billingEnd,
}: {
  status: PreSitelinePayAppStatus | null
  currentBilled: number | null
  billingEnd: string | null
}) => {
  return (
    (status === PreSitelinePayAppStatus.PAID || status === PreSitelinePayAppStatus.PROPOSED) &&
    currentBilled !== null &&
    billingEnd !== null
  )
}

/** Whether a pay app requires a form with a physical signature to be uploaded */
export const doesPayAppRequirePhysicalSignature = (
  payApp: Pick<PayApp, 'status' | 'physicalSignatureRequired' | 'uploadedFile'>
) => {
  return (
    payApp.physicalSignatureRequired !== PhysicalSignatureRequired.NONE_REQUIRED &&
    payApp.status === PayAppStatus.SIGNED &&
    !payApp.uploadedFile
  )
}

/**
 * Consider a pay app a draft if it is in draft status, or if it has been signed but still requires
 * a notarized form to be uploaded. Also consider it to be a draft if there was a failed integration
 * sync.
 */
export const needsDraftPayAppPackage = (
  payApp: Pick<PayApp, 'status' | 'physicalSignatureRequired' | 'uploadedFile'>
) => {
  return isPayAppDraftOrSyncFailed(payApp.status) || doesPayAppRequirePhysicalSignature(payApp)
}

/**
 * For retention-only pay apps, check if there is a concurrent progress pay app ongoing. A
 * concurrent progress pay app is defined as a pay app that has the same billing start and
 * end date.
 * @param project Project to check for a concurrent pay app
 * @param retentionPayApp Retention-only pay app to get a concurrent pay app for
 */
export function getConcurrentProgressPayAppId(
  contract?: ContractForPayApps,
  retentionPayApp?: PayAppForProgress
): string | undefined {
  if (!retentionPayApp || !retentionPayApp.retentionOnly || !contract) {
    return
  }
  const timeZone = contract.timeZone
  const progressPayApp = contract.payApps.find(
    (concurrentPayApp) =>
      !concurrentPayApp.retentionOnly &&
      moment
        .tz(concurrentPayApp.billingEnd, timeZone)
        .isSame(moment.tz(retentionPayApp.billingEnd, timeZone), 'day') &&
      moment
        .tz(concurrentPayApp.billingStart, timeZone)
        .isSame(moment.tz(retentionPayApp.billingStart, timeZone), 'day')
  )
  return progressPayApp?.id
}

export type BaseMetricsPayApp = {
  id: string
  retentionOnly: boolean
  status: PayAppStatus
  payAppNumber: number
  billingType: BillingType
}

export function getMetricsForPayApp(
  payApp: BaseMetricsPayApp,
  projectId: string,
  projectName: string
): MetricsForPayApp {
  const baseMetrics: MetricsForPayApp = {
    projectId,
    payAppId: payApp.id,
    payAppType: payApp.retentionOnly ? 'retention' : 'progress',
    payAppStatus: payApp.status,
    payAppNumber: payApp.payAppNumber,
    projectName,
    billingType: payApp.billingType,
  }

  const project = getProjectFromCache(projectId)
  if (project) {
    return {
      ...baseMetrics,
      projectName: project.name,
    }
  }

  return baseMetrics
}

export function getMetricsForPreSitelinePayApp(
  payApp: Pick<PreSitelinePayAppForProjectHome, 'id' | 'retentionOnly' | 'status' | 'payAppNumber'>,
  projectId: string
): MetricsForPreSitelinePayApp {
  const baseMetrics: MetricsForPreSitelinePayApp = {
    projectId,
    payAppId: payApp.id,
    payAppType: payApp.retentionOnly ? 'retention' : 'progress',
    payAppStatus: payApp.status,
    payAppNumber: payApp.payAppNumber,
    projectName: undefined,
  }

  const project = getProjectFromCache(projectId)
  if (project) {
    return {
      ...baseMetrics,
      projectName: project.name,
    }
  }

  return baseMetrics
}

/**
 * Checks if the new total billed is between 0 and totalValue, inclusive. If this returns
 * false, future pay apps may be impacted by the change or the change may be invalid.
 *
 * We have to use an inRange check to make sure we appropriately account for negative values. We
 * can't use:
 *   1. Simple comparisons (newTotalBilled < totalValue) because that breaks down if the total
 *      value is negative. i.e. if the newTotalBilled is -100 and the total value is -50, this
 *      should return false but it would return true.
 *   2. Absolute value based comparisons (Math.abs(newTotalBilled) < Math.abs(totalValue)) because
 *      that breaks down if the total value is negative but the total billed is positive. i.e. if
 *      the new total billed is 50 and the total value is -100, this should return false but it
 *      would return true.
 *
 * NOTE: the _.inRange check is not inclusive so we have to check the boundaries as well
 *
 * @param newTotalBilled the new total billed value being checked
 * @param totalValue the original total value for the sov line item
 */
export function isNewBilledInRange(newTotalBilled: number, totalValue: number): boolean {
  if (totalValue === 0 && newTotalBilled !== 0) {
    // If the total value is 0, no total billed value is in range.
    return false
  }
  return (
    _.inRange(newTotalBilled, totalValue) || newTotalBilled === totalValue || newTotalBilled === 0
  )
}

export type PayAppStartedProperties = Pick<PayApp, 'currentBilled' | 'previousRetentionBilled'>
export function isPayAppStarted(payApp: PayAppStartedProperties): boolean {
  return payApp.currentBilled !== 0 || payApp.previousRetentionBilled !== 0
}

/** Returns true if the user should be able to modify the SOV for a project */
export function canPreviewSovImport(
  contract: ContractForProjectHome,
  permissions: readonly Permission[]
) {
  return contract.status === ContractStatus.ACTIVE && permissions.includes(Permission.EDIT_INVOICE)
}

/** Returns true if the user should be able to add a change order to a pay app */
export function canAddChangeOrder(
  contract: ContractForPayApps,
  payApp: Pick<PayAppProperties, 'status' | 'billingEnd' | 'retentionOnly'>,
  permissions: readonly Permission[]
) {
  const payAppEnd = moment.tz(payApp.billingEnd, contract.timeZone)
  const changeOrderPayApps = contract.payApps.filter((projectPayApp) => {
    const billingEnd = moment.tz(projectPayApp.billingEnd, contract.timeZone)
    return billingEnd.isSameOrAfter(payAppEnd, 'day')
  })
  return (
    !payApp.retentionOnly &&
    changeOrderPayApps.every((projectPayApp) => isPayAppDraftOrSyncFailed(projectPayApp.status)) &&
    contract.status === ContractStatus.ACTIVE &&
    permissions.includes(Permission.EDIT_CHANGE_ORDER)
  )
}

/** Returns true if the user should be able to edit retention a pay app */
export function canViewRetention(
  contract: Pick<MinimalContractProperties, 'status'>,
  permissions: readonly Permission[]
) {
  return contract.status === ContractStatus.ACTIVE && permissions.includes(Permission.EDIT_INVOICE)
}

export type OverlappingPayApp = Pick<
  MinimalPayAppProperties,
  'id' | 'billingEnd' | 'billingStart' | 'retentionOnly'
>
/**
 * Returns whether or not the proposed pay app has a billing period that overlaps with any other
 * pay app on the project.
 */
export function findOverlappingPayApp<T extends OverlappingPayApp>({
  payApps,
  retentionOnly,
  billingStart,
  billingEnd,
  timeZone,
}: {
  payApps: T[]
  retentionOnly: boolean
  billingStart: moment.Moment
  billingEnd: moment.Moment
  timeZone: string
}): T | undefined {
  return payApps.find((payApp) => {
    const isSameType = payApp.retentionOnly === retentionOnly
    if (!isSameType) {
      // We're only looking for overlapping pay apps of the same type, since
      // progress & retention pay apps can be concurrent
      return false
    }
    const payAppBillingStart = moment.tz(payApp.billingStart, timeZone)
    const payAppBillingEnd = moment.tz(payApp.billingEnd, timeZone)
    return (
      billingStart.isBetween(payAppBillingStart, payAppBillingEnd, 'date', '[]') ||
      billingEnd.isBetween(payAppBillingStart, payAppBillingEnd, 'date', '[]') ||
      payAppBillingStart.isBetween(billingStart, billingEnd, 'date', '[]') ||
      payAppBillingEnd.isBetween(billingStart, billingEnd, 'date', '[]')
    )
  })
}

/** Returns a string that represents the status of the pay app */
export function getPayAppStatusString(payApp: Pick<PayAppProperties, 'status'>, t: TFunction) {
  switch (payApp.status) {
    case PayAppStatus.DRAFT:
      return t('projects.status.draft')
    case PayAppStatus.SIGNED:
      return t('projects.status.signed')
    case PayAppStatus.SYNCED:
      return t('projects.status.synced')
    case PayAppStatus.SYNC_FAILED:
      return t('projects.status.sync_failed')
    case PayAppStatus.SYNC_PENDING:
      return t('projects.status.sync_pending')
    case PayAppStatus.PROPOSED:
      return t('projects.status.submitted')
    case PayAppStatus.NOTARIZING_UNCONDITIONAL:
    case PayAppStatus.PAID:
      return t('projects.status.paid')
  }
}

/**
 * If a pay app had an attempted sync that failed, its still treated as a draft pay app in most
 * cases since nothing has been synced over to the integration yet.
 */
export function isPayAppDraftOrSyncFailed(status: PayAppStatus) {
  return status === PayAppStatus.DRAFT || status === PayAppStatus.SYNC_FAILED
}

/**
 * If a pay app is in sync pending state, its treated as a signed pay app in most cases because if
 * the sync is pending, it shouldn't be edited but hasn't yet been "submitted" (like signed state)
 */
export function isPayAppSignedOrSyncPending(status: PayAppStatus) {
  return status === PayAppStatus.SIGNED || status === PayAppStatus.SYNC_PENDING
}

/** Used for filtering pay app activities that have comments attached */
export function doesPayAppEventHaveInternalComment(event: PayAppEventProperties) {
  const typedEvent = event as TypedPayAppEvent<PayAppEventProperties>
  // Use a temp variable with an intialized state so any new event types added in the future won't
  // cause this util to error
  let hasInternalComment = false
  switch (typedEvent.type) {
    case PayAppEventType.REQUESTED_REVIEW:
    case PayAppEventType.SENT_FORMS_TO_SIGNER:
      hasInternalComment = typedEvent.metadata.message.length > 0
      break
    case PayAppEventType.PAY_APP_BACKUP_SUBMITTED:
    case PayAppEventType.PAY_APP_DRAFT_SUBMITTED:
    case PayAppEventType.PAY_APP_SUBMITTED:
    case PayAppEventType.UNCONDITIONAL_LIEN_WAIVER_SUBMITTED:
    case PayAppEventType.ADDED_BACKUP:
    case PayAppEventType.EDITED_LINE_ITEM:
    case PayAppEventType.EDITED_WORKSHEET_LINE_ITEM:
    case PayAppEventType.EDITED_QUICK_BILL:
    case PayAppEventType.EDITED_RETENTION:
    case PayAppEventType.EDITED_SOV:
    case PayAppEventType.GROUPED_ADDED_BACKUP:
    case PayAppEventType.GROUPED_EDITED_LINE_ITEM:
    case PayAppEventType.GROUPED_EDITED_WORKSHEET_LINE_ITEM:
    case PayAppEventType.GROUPED_EDITED_QUICK_BILL:
    case PayAppEventType.GROUPED_EDITED_SOV:
    case PayAppEventType.GROUPED_PAY_APP_SWORN_STATEMENT_EDITED:
    case PayAppEventType.PAY_APP_CREATED:
    case PayAppEventType.PAY_APP_EDITED_BALANCE_MANUALLY_CLOSED:
    case PayAppEventType.PAY_APP_MARKED_AS_PAID:
    case PayAppEventType.PAY_APP_NOTARIZING_UNCONDITIONAL:
    case PayAppEventType.PAY_APP_PAID_AMOUNT_UPDATED:
    case PayAppEventType.PAY_APP_PAID_IN_INTEGRATION:
    case PayAppEventType.PAY_APP_READ_SYNCED:
    case PayAppEventType.PAY_APP_SIGNED:
    case PayAppEventType.PAY_APP_SWORN_STATEMENT_EDITED:
    case PayAppEventType.PAY_APP_SYNC_FAILED:
    case PayAppEventType.PAY_APP_SYNC_PENDING:
    case PayAppEventType.PAY_APP_SYNCED:
    case PayAppEventType.REMOVED_BACKUP:
    case PayAppEventType.REVERTED_TO_DRAFT:
    case PayAppEventType.ROUNDED_LINE_ITEMS:
    case PayAppEventType.SAVED_FORM_VALUES: // eslint-disable-line @typescript-eslint/no-deprecated
    case PayAppEventType.SENT_COLLECTIONS_REMINDER:
    case PayAppEventType.CLEARED_FORM_VALUES:
      hasInternalComment = false
      break
  }
  return hasInternalComment
}

/** Handles querying and subscribing to pay app activities. Used on the pay app details page */
export function usePayAppActivitiesSubscription(payAppId: string) {
  const { data, loading, subscribeToMore } = usePayAppActivityQuery({
    variables: { payAppId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const subscribeToPayAppActivities = useCallback(() => {
    subscribeToMore({
      document: NewPayAppCommentDocument,
      variables: { payAppId },
      updateQuery: (prev, { subscriptionData }) => {
        const newData = subscriptionData.data as unknown as NewPayAppCommentSubscription | undefined
        if (!newData) {
          return prev
        }

        const newComment = newData.newPayAppComment

        return {
          ...prev,
          payApp: {
            ...prev.payApp,
            comments: _.uniqBy([newComment, ...prev.payApp.comments], (comment) => comment.id),
          },
        }
      },
    })

    subscribeToMore({
      document: NewPayAppEventDocument,
      variables: { payAppId },
    })
  }, [payAppId, subscribeToMore])

  // Subscribe to pay app activities on mount
  // Note: we only want to subscribe to a pay app's activities a single time. Pay attention to the dependecies of subscribeToPayAppActivities,
  // as this will affect how often this useEffect gets triggered
  useEffect(() => {
    subscribeToPayAppActivities()
  }, [subscribeToPayAppActivities])

  return { data, loading }
}

export enum SubmitHistoryActivityFilter {
  ALL = 'ALL',
  COMMENTS = 'COMMENTS',
}

type PayAppForDefaultDates = Pick<
  PayAppProperties,
  'billingStart' | 'billingEnd' | 'retentionOnly'
> & {
  payAppDueDate?: string
}

export const MAX_DUE_TO_GC_DAY_OF_MONTH = 28

/** Returns the default billing dates and due date for a pay app, based on the last pay app or contract settings */
export function getDefaultPayAppDates({
  payAppMonth,
  payApps,
  timeZone,
  payAppDueOnDayOfMonth,
}: {
  payAppMonth: Moment
  payApps: PayAppForDefaultDates[]
  timeZone: string
  payAppDueOnDayOfMonth: number
}): { billingStart: Moment; billingEnd: Moment; dueDate: Moment } {
  const orderedPayApps = sortPayAppsByBillingEnd(payApps, 'asc', timeZone)
  const lastPayApp = _.findLast(orderedPayApps, (payApp) =>
    moment.tz(payApp.billingEnd, timeZone).isSameOrBefore(payAppMonth, 'month')
  )

  let billingStart = payAppMonth.clone().startOf('month')
  if (lastPayApp) {
    const lastBillingStart = moment.tz(lastPayApp.billingStart, timeZone)
    const lastBillingEnd = moment.tz(lastPayApp.billingEnd, timeZone)
    const billingStartInMonth = lastBillingStart
      .clone()
      .year(payAppMonth.year())
      .month(payAppMonth.month())
    billingStart = adjustDateByMonths(billingStartInMonth, lastBillingEnd, lastBillingStart)
  }

  let billingEnd = payAppMonth.clone().endOf('month')
  if (lastPayApp) {
    const lastPayAppBillingEnd = moment.tz(lastPayApp.billingEnd, timeZone)
    billingEnd = matchEndOfMonth(
      lastPayAppBillingEnd.clone().year(payAppMonth.year()).month(payAppMonth.month()),
      lastPayAppBillingEnd
    )
  }

  return {
    billingStart,
    billingEnd,
    dueDate: payAppMonth.clone().set('date', payAppDueOnDayOfMonth).endOf('d'),
  }
}

export function filterSubmittedPayApps<T extends Pick<PayAppProperties, 'status'>>(
  payApps: readonly T[]
): T[] {
  return payApps.filter(
    ({ status }) => isPayAppSubmittedOrSynced(status) || isPayAppCompleted(status)
  )
}

interface MinimalSubmittedPreSitelinePayApp<T extends MinimalPreSitelinePayAppProperties> {
  currentBilled: NonNullable<T['currentBilled']>
  billingEnd: NonNullable<T['billingEnd']>
  status: NonNullable<T['status']>
  retentionOnly: T['retentionOnly']
}

export function filterSubmittedPreSitelinePayApps<T extends MinimalPreSitelinePayAppProperties>(
  preSitelinePayApps: readonly T[]
): MinimalSubmittedPreSitelinePayApp<T>[] {
  const submittedPayApps = preSitelinePayApps.filter(
    isPreSitelinePayAppSubmitted
  ) as MinimalSubmittedPreSitelinePayApp<T>[]
  // Cast to MinimalSubmittedPreSitelinePayApp to enforce billing values and status are not null
  return submittedPayApps as MinimalSubmittedPreSitelinePayApp<T>[]
}

type ContractWithPayApps = {
  payApps: readonly MinimalPayAppProperties[]
  preSitelinePayApps: readonly MinimalPreSitelinePayAppProperties[]
}

export function deriveAllSubmittedPayAppsFromContract(contract: ContractWithPayApps) {
  const submittedPayApps = filterSubmittedPayApps(contract.payApps)
  const submittedPayAppsPreSiteline = filterSubmittedPreSitelinePayApps(contract.preSitelinePayApps)
  return [...submittedPayApps, ...submittedPayAppsPreSiteline]
}

export const currentPayAppNet = (
  payApp: Pick<PayApp, 'currentBilled' | 'currentRetention' | 'previousRetentionBilled'>
) => {
  return payApp.currentBilled - payApp.currentRetention + payApp.previousRetentionBilled
}

export function currentPayAppAmountDue(
  payApp: Pick<PayApp, 'amountPaid' | 'isBalanceManuallyClosed' | 'status' | 'amountDuePostTax'>
) {
  const isPaidStatus = payApp.status === PayAppStatus.PAID

  const isSubmittedStatus = [
    PayAppStatus.PROPOSED,
    PayAppStatus.NOTARIZING_UNCONDITIONAL,
    PayAppStatus.SYNCED,
  ].includes(payApp.status)

  if (!isPaidStatus && !isSubmittedStatus) {
    return 0
  }

  // We use the post-tax amount for showing the full amount due on a pay app
  const amountDue = payApp.amountDuePostTax

  if (isSubmittedStatus) {
    return amountDue
  }

  if (payApp.isBalanceManuallyClosed) {
    return 0
  }

  // If `amountPaid` is null on a pay app, fall back to the total amount due
  const remainingBalance = amountDue - (payApp.amountPaid ?? amountDue)
  return remainingBalance
}

export function currentPreSitelinePayAppAmountDue(
  payApp: Pick<
    PreSitelinePayApp,
    'status' | 'paymentDue' | 'isBalanceManuallyClosed' | 'amountPaid'
  >
) {
  const amountDue = payApp.paymentDue ?? 0
  if (payApp.status === PreSitelinePayAppStatus.PROPOSED) {
    return payApp.paymentDue ?? 0
  }

  if (payApp.isBalanceManuallyClosed) {
    return 0
  }

  const remainingBalance = amountDue - (payApp.amountPaid ?? 0)
  return remainingBalance
}

export function isPayAppFullyPaid(
  payApp: Pick<PayApp, 'amountPaid' | 'isBalanceManuallyClosed' | 'status' | 'amountDuePostTax'>
) {
  if (payApp.status !== PayAppStatus.PAID) {
    // The pay app status needs to be PAID. Knowing the amount due equals the amount paid isn't
    // enough, because the pay app could still be in draft with $0 due.
    return false
  }
  const currentAmountDue = currentPayAppAmountDue(payApp)
  return currentAmountDue === 0
}

export function isPreSitelinePayAppFullyPaid(
  payApp: Pick<
    PreSitelinePayApp,
    'amountPaid' | 'isBalanceManuallyClosed' | 'status' | 'paymentDue'
  >
) {
  if (payApp.status !== PreSitelinePayAppStatus.PAID) {
    // The pay app status needs to be PAID to be considered fully paid
    return false
  }
  // If the user didn't enter anything under paid amount, assume it is fully paid. Otherwise we
  // would show it as partially paid with $0 paid, which is more confusing.
  if (payApp.amountPaid === null) {
    return true
  }
  const currentAmountDue = currentPreSitelinePayAppAmountDue(payApp)
  return currentAmountDue === 0
}

type SovLineItemForProjectHome = NonNullable<ContractForProjectHome['sov']>['lineItems'][number]

export function getProjectCollectionsMetrics({
  payApps,
  preSitelinePayApps,
  lineItems,
  billingType,
  preSitelineBilled,
  preSitelineRetention,
}: {
  payApps: Pick<
    PayAppForProjectHome,
    'status' | 'amountDuePostTax' | 'amountPaid' | 'isBalanceManuallyClosed'
  >[]
  preSitelinePayApps: Pick<
    PreSitelinePayAppForProjectHome,
    'status' | 'amountPaid' | 'paymentDue' | 'isBalanceManuallyClosed'
  >[]
  lineItems: Pick<SovLineItemForProjectHome, 'previousBilled'>[]
  billingType: BillingType
  preSitelineBilled: number | null
  preSitelineRetention: number | null
}) {
  // For normal Siteline pay apps, we sum up the remaining amount due for the amount outstanding,
  // and sum up the amount paid for the amount collected to date
  const payAppsCollectedToDate = _.sumBy(payApps, (payApp) =>
    payApp.status === PayAppStatus.PAID ? (payApp.amountPaid ?? payApp.amountDuePostTax) : 0
  )
  const payAppsAmountOutstanding = _.sumBy(payApps, (payApp) => currentPayAppAmountDue(payApp))

  // For pre-Siteline billing, we assume that all billing has been collected except for billing on
  // pre-Siteline pay apps that are still outstanding. We make the assumption that pre-Siteline pay
  // apps are expected to be added mainly for tracking aging invoices, so if a pre-Siteline pay app
  // has not been added then the billing has been collected. Our logic is therefore:
  // - The amount collected is the total amount due on pre-Siteline billing (i.e. the net amount of
  // progress less retention), minus the amount outstanding on pre-Siteline pay apps
  // - The amount outstanding is the total amount outstanding on pre-Siteline pay apps
  const preSitelineAmountOutstanding = _.sumBy(preSitelinePayApps, (payApp) =>
    currentPreSitelinePayAppAmountDue(payApp)
  )
  let preSitelineGrossAmountBilled = _.sumBy(lineItems, (lineItem) => lineItem.previousBilled)
  if (billingType === BillingType.TIME_AND_MATERIALS) {
    preSitelineGrossAmountBilled = preSitelineBilled ?? 0
  }
  const preSitelineNetAmountBilled = preSitelineGrossAmountBilled - (preSitelineRetention ?? 0)
  const preSitelineAmountCollected = preSitelineNetAmountBilled - preSitelineAmountOutstanding

  return {
    collectedToDate: payAppsCollectedToDate + preSitelineAmountCollected,
    amountOutstanding: payAppsAmountOutstanding + preSitelineAmountOutstanding,
  }
}

export function getProjectMetrics({
  payApps,
  totalContractValue,
  lineItems,
  timeZone,
  billingType,
  preSitelineBilled,
  preSitelineRetention,
}: {
  payApps: (PayAppForSorting &
    Pick<
      PayAppForProjectHome,
      'status' | 'balanceToFinish' | 'totalRetention' | 'currentBilled' | 'currentRetention'
    >)[]
  totalContractValue: number
  lineItems: Pick<SovLineItemForProjectHome, 'previousBilled'>[]
  timeZone: string
  billingType: BillingType
  // This is filled only for T&M contract. Other contracts should calculate pre-Siteline billing
  // from the SOV line items.
  preSitelineBilled: number | null
  // This is filled for all contracts, and takes into account a pre-Siteline retention override
  // if one exists
  preSitelineRetention: number | null
}) {
  const submittedPayApps = filterSubmittedPayApps(payApps)
  const latestPayApp = _.first(sortPayAppsByBillingEnd(payApps, 'desc', timeZone))
  const latestSubmittedPayApp = _.first(sortPayAppsByBillingEnd(submittedPayApps, 'desc', timeZone))

  const grossTimeAndMaterialsBilledToDate =
    _.sumBy(submittedPayApps, (payApp) => payApp.currentBilled) + (preSitelineBilled ?? 0)
  const netTimeAndMaterialsBilledToDate =
    _.sumBy(submittedPayApps, (payApp) => payApp.currentBilled - payApp.currentRetention) +
    (preSitelineBilled ?? 0) -
    (preSitelineRetention ?? 0)

  const totalGrossBilledPreSiteline = _.sumBy(lineItems, (lineItem) => lineItem.previousBilled)
  // Use `preSitelineRetention` here instead of calculating by line item because there may be
  // a pre-Siteline retention override
  const totalNetBilledPreSiteline = totalGrossBilledPreSiteline - (preSitelineRetention ?? 0)

  let totalGrossBilledToDate = totalGrossBilledPreSiteline
  let totalNetBilledToDate = totalNetBilledPreSiteline
  if (latestSubmittedPayApp) {
    totalGrossBilledToDate = totalContractValue - latestSubmittedPayApp.balanceToFinish
    totalNetBilledToDate = totalGrossBilledToDate - latestSubmittedPayApp.totalRetention
  }

  let totalGrossBilledAndDraftToDate = totalGrossBilledPreSiteline
  let totalNetBilledAndDraftToDate = totalNetBilledPreSiteline
  if (latestPayApp) {
    totalGrossBilledAndDraftToDate = totalContractValue - latestPayApp.balanceToFinish
    totalNetBilledAndDraftToDate = totalGrossBilledAndDraftToDate - latestPayApp.totalRetention
  }

  const percentComplete = decimalToPercent(
    safeDivide(totalGrossBilledToDate, totalContractValue, 0),
    1
  )
  const totalLeftToBill = totalContractValue - totalGrossBilledToDate

  let retentionHeld = preSitelineRetention ?? 0
  if (latestSubmittedPayApp) {
    retentionHeld = latestSubmittedPayApp.totalRetention
  }

  return {
    percentBilled: percentComplete,
    grossBilledToDate:
      billingType === BillingType.TIME_AND_MATERIALS
        ? grossTimeAndMaterialsBilledToDate
        : totalGrossBilledToDate,
    grossBilledAndDraft: totalGrossBilledAndDraftToDate,
    netBilledToDate:
      billingType === BillingType.TIME_AND_MATERIALS
        ? netTimeAndMaterialsBilledToDate
        : totalNetBilledToDate,
    netBilledAndDraft: totalNetBilledAndDraftToDate,
    leftToBill: totalLeftToBill,
    retentionHeld,
    totalContractValue,
  }
}

export function useProjectMetrics(contract: ContractForProjectHome) {
  return useMemo(() => {
    const payApps = [...contract.payApps]
    const lineItems = [...(contract.sov?.lineItems ?? [])]
    const billingMetrics = getProjectMetrics({
      payApps,
      totalContractValue: contract.sov?.totalValue ?? 0,
      lineItems,
      timeZone: contract.timeZone,
      billingType: contract.billingType,
      preSitelineBilled: contract.preSitelineBilled,
      preSitelineRetention: contract.preSitelineRetention,
    })
    const collectionsMetrics = getProjectCollectionsMetrics({
      payApps,
      preSitelinePayApps: [...contract.preSitelinePayApps],
      lineItems,
      billingType: contract.billingType,
      preSitelineBilled: contract.preSitelineBilled,
      preSitelineRetention: contract.preSitelineRetention,
    })
    return { ...billingMetrics, ...collectionsMetrics }
  }, [contract])
}

/**
 * Returns the pay app due date, depending on the user role.
 *  - For field guests and project managers, this is the internal due date (due to accounting)
 *  - For accountants and administrators, this is the external due date (due to the GC)
 */
export function payAppDueDateForRole(
  payApp: Pick<PayApp, 'timeZone' | 'payAppDueDate' | 'internalDueDate'>,
  role: CompanyUserRole
): Moment {
  const shouldUserInternalDate =
    role === CompanyUserRole.PROJECT_MANAGER || role === CompanyUserRole.FIELD_GUEST
  const dueDate = shouldUserInternalDate ? payApp.internalDueDate : payApp.payAppDueDate
  return moment.tz(dueDate, payApp.timeZone)
}

export function getPayAppStatusText(
  payApp: Pick<
    PayApp,
    | 'status'
    | 'draftSubmittedAt'
    | 'currentBilled'
    | 'previousRetentionBilled'
    | 'invoiceReady'
    | 'amountDuePostTax'
    | 'amountPaid'
    | 'isBalanceManuallyClosed'
    | 'texturaRejectedAt'
  >,
  t: TFunction,
  isPastDue: boolean,
  gcPortalName?: string
) {
  let text = ''
  let textColor = colors.grey50
  let bubbleColor = colors.grey30
  switch (payApp.status) {
    case PayAppStatusEnum.DRAFT:
      if (payApp.draftSubmittedAt !== null) {
        text = t('projects.status.draft_submitted')
        textColor = colors.blue50
        bubbleColor = colors.blue30
      } else if (payApp.texturaRejectedAt) {
        text = t('projects.status.textura_rejected')
        textColor = colors.red50
        bubbleColor = colors.red30
      } else if (isPastDue) {
        text = t('projects.status.past_due')
        textColor = colors.red50
        bubbleColor = colors.red30
      } else if (isPayAppStarted(payApp) === false) {
        text = t('projects.status.not_started')
        textColor = colors.grey50
        bubbleColor = 'transparent'
      } else if (payApp.invoiceReady) {
        text = t('projects.status.invoice_ready')
        textColor = colors.blue50
        bubbleColor = colors.blue30
      } else {
        text = t('projects.status.in_progress')
        textColor = colors.yellow50
        bubbleColor = colors.yellow30
      }
      break
    case PayAppStatusEnum.SIGNED:
      text = t('projects.status.signed')
      textColor = colors.purple50
      bubbleColor = colors.purple30
      break
    case PayAppStatusEnum.PROPOSED:
      text = t('projects.status.submitted')
      textColor = colors.green50
      bubbleColor = colors.green30
      break
    case PayAppStatusEnum.SYNCED:
      text = gcPortalName
        ? t('projects.status.synced_to', { integration: gcPortalName })
        : t('projects.status.synced')
      textColor = colors.green50
      bubbleColor = colors.green30
      break
    case PayAppStatusEnum.NOTARIZING_UNCONDITIONAL:
      text = t('projects.status.notarizing')
      textColor = colors.green50
      bubbleColor = colors.green30
      break
    case PayAppStatusEnum.PAID: {
      const isPartiallyPaid = !isPayAppFullyPaid(payApp)
      text = isPartiallyPaid ? t('projects.status.partially_paid') : t('projects.status.paid')
      textColor = isPartiallyPaid ? colors.yellow50 : colors.green50
      bubbleColor = isPartiallyPaid ? colors.yellow30 : colors.green30
      break
    }
    case PayAppStatusEnum.SYNC_PENDING:
      text = t('projects.status.sync_pending')
      textColor = colors.purple50
      bubbleColor = colors.purple30
      break
    case PayAppStatusEnum.SYNC_FAILED:
      text = t('projects.status.sync_failed')
      textColor = colors.red50
      bubbleColor = colors.red30
      break
  }
  return { text, textColor, bubbleColor }
}

export function payAppHasAnyProgressBilling(
  payApp: Pick<PayApp, 'billingType'> & {
    // This makes the typing work for the sage 100 pay app query and the pay app queries for other ERPs
    progress: ReadonlyArray<Pick<PayApp['progress'][number], 'currentBilled'>>
    rateTableItems: ReadonlyArray<Pick<PayApp['rateTableItems'][number], 'currentUnitsBilled'>>
  }
): boolean {
  if (
    payApp.billingType === BillingType.LUMP_SUM ||
    payApp.billingType === BillingType.UNIT_PRICE
  ) {
    const linesBilled = payApp.progress.filter((progressLine) => progressLine.currentBilled !== 0)
    return linesBilled.length > 0
  }

  if (payApp.billingType === BillingType.TIME_AND_MATERIALS) {
    const rateTableItems = payApp.rateTableItems.map((item) => item.currentUnitsBilled !== 0)
    return rateTableItems.length > 0
  }

  return false
}
